import React from "react";
import BookingSection from "../components/BookingSection";
import Layout from "../components/Layout";
import PackagesSection from "../components/PackagesSection";
import PackagesHero from "../components/Plans&PackagesHero";
import RecoverySection from "../components/RecoverySection";
import Testimonial from "../components/Testimonial";
import ServicesList from "../components/ServicesList";
import Seo from "../components/SEO";

const META_DATA = {
  title: "Packages | Washington DC | HighKey Balance",
  description: `Book a full wellness package to take a holistic approach to your recovery and healing. Available now at Washington D.C.'s premiere wellness and recovery center.`,
};

const PlansPackages = () => {
  return (
    <Layout>
      <Seo {...META_DATA} />
      <PackagesHero />
      <ServicesList />
      <PackagesSection />
      <Testimonial />
      <RecoverySection />
      <BookingSection />
    </Layout>
  );
};

export default PlansPackages;
