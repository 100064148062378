import React from "react";

const ServicesList = ({toggleFunc}) => {
  return (
    <section>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Custom Therapeutic Massage
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
          Our Custom Therapeutic Massage is designed to target your specific pain points and promote healing. This tailored approach alleviates tension, 
              releases soft tissue restrictions, and improves alignment and circulation. By blending various techniques, 
              we effectively reduce pain and enhance mobility, empowering you to excel in the gym, at work, and in your daily activities. 
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Custom Therapeutic Massage</th>
                    <th className="w-1/4">Rate</th>
                </tr>
               <tr>
                    <td>90-Min Initial Visit </td>
                    <td>$195</td>
                </tr>
                <tr>
                    <td>60-Min Follow-up Visit </td>
                    <td>$170</td>
                </tr>
               
                <tr>
                    <td>30-Min Follow-up Visit</td>
                    <td>$90</td>
                </tr>
               
               
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Acupuncture
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
               Acupuncture is a therapeutic practice that uses thin needles inserted into specific points on the body to stimulate the Central Nervous System and release endorphins, which are natural pain relievers. This treatment effectively supports overall health maintenance, alleviates pain, reduces stress, regulates the nervous system and hormonal imbalances, and improves digestion and mental well-being. 
              We also offer complementary treatments such as cupping, gua sha, infrared heat therapy, and electrical stimulation. 
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Acupuncture</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>90-Min Initial Visit</td>
                    <td>$245</td>
                </tr>
                <tr>
                    <td>60-Min Follow-up Visit</td>
                    <td>$170</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Cupping therapy
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
               Cupping therapy utilizes suction to break down muscle adhesions, scar tissue, and trigger points, enhancing blood flow and promoting relaxation. This technique effectively reduces pain and inflammation while alleviating chronic discomfort, 
              supporting detoxification, and boosting the immune system, making it a powerful tool for overall wellness. 
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Cupping Therapy</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>20-Min</td>
                    <td>$59</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                SportStretch Therapy
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
               Unlock your body’s potential with our therapist-assisted SportStretch sessions. Designed to enhance flexibility and blood flow, 
              these customized stretches help you stay injury-free and maximize your performance. 
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>SportStretch Therapy</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>60-Min </td>
                    <td>$135</td>
                </tr>
                <tr>
                    <td>30-Min</td>
                    <td>$75</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Whole Body Cryotherapy
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                Revitalize your body in just 3 minutes with our cryotherapy treatment. This powerful experience uses extreme cold, 
              reaching -184°F, to reduce inflammation, boost endorphins, and accelerate recovery. 
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Whole Body Cryotherapy</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>15-Min</td>
                    <td>$75</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Infrared Sauna
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                Experience relaxation and detoxification while soothing sore muscles and improving skin health in our Infrared Sauna. The deep-penetrating heat enhances circulation and promotes detoxification, creating a rejuvenating environment 
              that releases toxins and alleviates tension. For the best results, remember to stay hydrated! 
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Infrared Sauna</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>50-Min</td>
                    <td>$45</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Compression Therapy
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                Relax and recover with our pneumatic compression therapy. Designed to increase blood flow and reduce inflammation, 
              these recovery boots will leave you feeling refreshed and ready to conquer your next challenge. 
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Compression Therapy</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>45-Min</td>
                    <td>$40</td>
                </tr>
            </table>
          </div>
        </div>
    </section>
  );
};

export default ServicesList;
