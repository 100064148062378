import classNames from "classnames";
import React from "react";
import Button from "../Button";

const PackagesCards = ({ data }) => {
  return (
    <>
      {data.map((item) => (
        <div
          key={item.name}
          className="bg-gray_50 pricecard_box_shadow pb-[49px] rounded-b-lg"
        >
          <div className="w-full flex flex-col pl-3  bg-gray_500 h-[200px] rounded-lg ">
            <h4 className="font-semibold text-3xl leading-[37px] text-white fontFamily2 mt-9 mb-6">
              {item.name}
            </h4>
            <h5 className=" text-white text-xl leading-[27px] font-semibold fontFamily2">
              ${item.price} {item.perSession && (
                <span className="text-sm"> (${item.perSession}/session)</span>
              
              )}
            </h5>
          </div>
          <div className="px-4">
            <p className="mt-6 uppercase mb-6 font-semibold text-purple_400 flex items-center justify-center h-7 px-4 w-fit bg-purple_100 rounded-2xl fontFamily2 text-sm leading-[18px] ">
              {item.quantity} Pack
            </p>
            <p className="mb-12 text-base leading-7 font-normal fontFamily1 relative text-typography_body before:content-'' before:w-[123px] before:h-[2px] before:rounded-sm before:bg-purple_400 before:absolute before:left-0 before:bottom-[-24px] ">
              {item.header[0]}<br></br>
              {item.header[1]}
            </p>
            <ul
              className={classNames(
                "",
                item.points.length < 2 ? "list-none" : "list-disc pl-6"
              )}
            >
              {item.points.map((point) => (
                <li
                  key={point}
                  className="font-semibold text-base text-typography_body fontFamily1"
                >
                  {point}
                </li>
              ))}
            </ul>
            <a href={item.bookNow}>
            <Button
              texts={"Book Now"}
              customClass={
                "uppercase text-sm font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em]  w-[8rem] rounded-sm h-10 transition hover:bg-green_400 hover:text-purple mt-4"
              }
            />
          </a>
          </div>
        </div>
      ))}
    </>
  );
};

export default PackagesCards;
